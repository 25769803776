.responsive-table {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 35px;
    width: 100%;
    /* padding: 10px; */
    /* border: 1px solid #ccc; */
    /* border-radius: 8px; */
}

.cell {
    /* padding: 5px; */
    /* border-radius: 4px; */
    /* border: 1px solid #ccc; */
}