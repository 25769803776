.header {
    height: 50px;
    min-width: "65%";
    max-width: 1300px;
    padding: 0 20px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header a {
    text-decoration: none;
    color: white;
}

.header img {
    max-width: 75%;
}
