.gallery-img>img {
    aspect-ratio: 16 / 9;
}

.gallery-root {
    align-content: center;
}

textarea {
    width: 100%;
    height: 300px;
}

.cd_green {
    color: var(--cd-green);
    font-size: large;
    margin: 5px 0;
}

.link {
    color: var(--cd-green);
    text-decoration: none;
}

.file-link {
    color: white;
    text-decoration: none;
}

.cd_green_normal {
    color: var(--cd-green);
}

@media screen and (max-width: 600px) {
    h1 {
        font-size: 24px;
    }

    h2 {
        font-size: 21px;
    }

    p {
        font-size: 16px;
    }
    
}
