.footer {
    margin-top: 50px;
}

.footer-bar {
    width: 100%;
    min-width: 100%;
    background-color: var(--cd-green);
    color: black;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    justify-content: center;
    row-gap: 20px;
    height: 60px;
}

.footer-bar-items {
    display: flex;
    column-gap: 20px;
    min-width: 100%;
    max-width: 1300px;
    /* padding: 0 20px; */
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 450px) {
    .footer-bar {
        flex-direction: column;
    }
}

.footer-bar a {
    color: white;
    background-color: var(--cd-black);
    border: 0;
    padding: 12px 40px 12px 40px;
    border-radius: 50px 50px 0 50px;
    font-weight: bold;
    font-size: 14px;
    text-decoration: none;
}

.footer-root {
    font-family: 'Euclid Circular A', Helvetica, Arial, Lucida, sans-serif;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    min-width: 65%;
    max-width: 1300px;
    padding: 0 20px;
    margin: auto;
    padding-bottom: 100px;
}

.footer-root .logo {
    padding-top: 80px;
    padding-bottom: 50px;
    height: 29px;
    max-width: 100%;
}

.footer-root p {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}

.footer-root p:not(:last-child) {
    padding-bottom: 1em;
}

.footer-root .text {
    margin-bottom: 60px;
    display: flex;
    column-gap: 100px;
}

.footer-root .text>div:not(:last-child)>div {
    margin-bottom: 30px;
}

.footer-root .text>div:not(:last-child)>div:last-child {
    margin-bottom: 0px;
}

@media (max-width: 850px) {
    .footer-root .text {
        flex-direction: column;
    }

    .footer-root .text>div:not(:last-child)>div:last-child {
        margin-bottom: 30px;
    }
}


.footer-root table {
    width: 100%;
}

.footer-root tr:not(:first-child) {
    height: 80px;
}

.footer-root td:first-child {
    width: 4%;
}

.footer-root a {
    text-decoration: none;
    color: inherit;
    transition: color 300ms ease 0ms;
}

.footer-root a:hover {
    color: var(--cd-green);
}

.footer-root p>a {
    font-weight: bold;
}

.footer-root .socials {
    display: flex;
    width: 100%;
    justify-content: space-between;
    max-width: 300px;
}

.footer-root .socials img {
    filter: brightness(0) saturate(100%) invert(88%) sepia(75%) saturate(600%) hue-rotate(23deg) brightness(91%) contrast(112%);
    transition-duration: 300ms;
}

.footer-root .socials img:hover {
    filter: brightness(0) saturate(100%) invert(88%) sepia(75%) saturate(600%) hue-rotate(23deg) brightness(91%) contrast(112%) grayscale(100%) brightness(1000%);
}